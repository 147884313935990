.TasksView {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .TasksView-header {
    flex-basis: 10vh;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    > .TasksView-header-text {
      margin-top: 12px;
    }
    p {
      color: rgba($color: #FFF, $alpha: 0.7);
      margin: 0;
    }
  }
}

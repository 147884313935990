.List {
  width: 100%;
  height: 40vw;
  max-height: 200px;
  .List-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    * {
      flex-basis: 50%;
    }  
    .List-description {

      * {
        margin: 0;
        padding: 0;
      }
      p {
        font-weight: 600;
        font-size: 20px;
      }
      span {
        font-size: 0.8em;
        color: #888;
      }
    }
  }
}

@media (min-width: 768px) {
  .List {
    width: 200px;
  }
}
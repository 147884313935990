.Task {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em 0;
  .Task-text {
    p {
      margin:0;
      padding: 0;
    }
    .Task-label {
      font-weight: 600;
    }
    .Task-date {
      margin-top: 0.3em;
      color: #888;
      font-size: 0.8em;
    }
  }
}
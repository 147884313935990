.ListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto; 
  grid-column-gap: 16px;
  grid-row-gap: 16px;

}
@media (min-width: 500px) {
  .ListContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .ListContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    > * {
      margin-right: 16px;
    }
  }
}
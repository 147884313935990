.Button {
  width: 100%;
  height: 50px;
  text-align: center;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: 300ms ease;
  &:active span {
    transform: scale(0.9);
  }
}
body {
  color: #353535;
  * {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

a {
  text-decoration: none;
  color: var(--black);
}